import { Pipe, PipeTransform } from '@angular/core';
import { TimeTracking } from '../models/timeTracking';

@Pipe({
  name: 'calculateDurationOfTimeTrackingGroup',
  standalone: true
})
export class ToroCalculateDurationOfTimeTrackingGroupPipe implements PipeTransform {

  transform(timeTrackingRecords: TimeTracking[]): number {
    
    //get sum of all time tracking durations
    let totalDuration: number = timeTrackingRecords.reduce((accumulator, row) => {

      if(row.is_record_dirty) {

        return accumulator + row.duration;

      } else {
        
        return accumulator;
      }

    }, 0);

    return totalDuration;

  }

}
