import { Pipe, PipeTransform } from '@angular/core';
import { parse, format } from 'date-fns';
import { ToroDateService } from '../services/date.service';

@Pipe({
  name: 'formatCreditCardExpDate',
  standalone: true
})
export class ToroFormatCreditCardExpDatePipe implements PipeTransform {

  constructor(
    private dateService: ToroDateService
  ) { }

  transform(card_exp_month: string, card_exp_year: string): string {
    
    let currentTime = <Date>this.dateService.convertUtcToUserTimezone(Date.now(), 'Date');

    let cc_date = parse(`${card_exp_year}-${card_exp_month}-01`, 'yyyy-MM-dd', currentTime);
    
    let dateString = format(cc_date, 'MM/yy');
    
    return dateString;

  }

}
