import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { GenericDialogService } from 'src/app/services/generic-dialog.service';
import { parseISO } from 'date-fns';
import { mergeMap } from 'rxjs/operators';
import { ToroProjectService, ToroChecklistService, Project, User } from 'toro-lib';

@Component({
  selector: 'app-checklist-onboarding',
  templateUrl: './checklist-onboarding.component.html',
  styleUrls: ['./checklist-onboarding.component.scss'],
  providers: [DialogService, GenericDialogService]
})
export class ChecklistOnboardingComponent implements OnInit {

  maxChecks: number = 4;
  checksDone: number = 0;
  earliestStep: number = 0;

  checklistData!: any[];

  user!: User;

  donutChart!: Highcharts.Chart;

  updateChecklistSubscription!: Subscription;

  minimized: boolean = false;

  loading: boolean = false;

  constructor(
    private onboardingService: OnboardingService,
    private router: Router,
    private genericDialogService: GenericDialogService,
    private projectService: ToroProjectService,
    private checklistService: ToroChecklistService,
  ) {}

  ngOnInit(): void {

    this.user = JSON.parse( localStorage.getItem('user') || '{}' );

    this.checkPermissions();

    if(this.user.settings.onboarding_checklist.hide_onboarding != '1') {
      if(this.getStepCount() == 0) {
        this.checkStepsBeforeRelease();
      } else {
        this.refresh();
      }

      this.updateChecklistSubscription = this.checklistService.checklistUpdate
        .subscribe(() => {
          this.user = JSON.parse( localStorage.getItem('user') || '{}' );
          this.refresh();
        })
    }
  }

  refresh(save: boolean = false) {
    if(save) {
      this.onboardingService.setUserSetting({
        onboarding_checklist: {
          edit_profile_done: this.user.settings.onboarding_checklist.edit_profile_done,
          create_comp_done: this.user.settings.onboarding_checklist.create_comp_done,
          create_proj_cat_done: this.user.settings.onboarding_checklist.create_comp_done,
          time_track_done: this.user.settings.onboarding_checklist.time_track_done,
          hide_onboarding: this.user.settings.onboarding_checklist.hide_onboarding
        }}).subscribe();
    }

    this.checksDone = this.getStepCount();
    this.earliestStep = this.getEarliestStep();
    this.refreshList();
    setTimeout(() => {
      this.refreshDonut();
    })
  }

  refreshList() {
    const isAtLeastManager = this.user.active_workspace_userlevel_id >= 2;

    this.checklistData = [
      {
        title: "Edit your profile",
        visible: true,
        instruction: "Enter your name and set your time zone for accurate time tracking",
        isComplete: this.getProgress('edit_profile_done'),
        clicked: () => {
          this.router.navigateByUrl(`my-account`)
            .then(() => {
              setTimeout(() => {
                this.onboardingService.triggerOpenModal();
              }, 100)
            })
        }
      },
      {
        title: "Create a company",
        visible: isAtLeastManager,
        instruction: "Kickstart by introducing the client’s company you’re working with ",
        isComplete: this.getProgress('create_comp_done'),
        clicked: () => {
          this.router.navigateByUrl(`projects`)
            .then(() => {
              setTimeout(() => {

                // opens new-company modal
                this.projectService.finishedProjectOnboarding("company");
                
              }, 100)
            })
        }
      },
      {
        title: "Add a project and task category",
        visible: isAtLeastManager,
        instruction: "Create a new project and add a task category to it",
        isComplete: this.getProgress('create_proj_cat_done'),
        clicked: () => {
          this.router.navigateByUrl(`projects`)
            .then(() => {
              setTimeout(() => {

                // opens create-project modal
                this.projectService.finishedProjectOnboarding("project");

              }, 100)
            })
        }
      },
      {
        title: "Track your first task",
        visible: true,
        instruction: "Consider naming your task and choosing a project and category before hitting “Start”",
        isComplete: this.getProgress('time_track_done'),
        clicked: () => {
          this.router.navigateByUrl(`timer`);
        }
      }
    ];
  }

  refreshDonut() {
    const data = [
      {
        y: this.checksDone,
        color: '#6D6CE9',
        custom: {
          isPurple: true,
        }
      },
      {
        y: this.maxChecks - this.checksDone,
        color: '#D0D0EC',
        custom: {
          isPurple: false,
        }
      }
    ];

    this.donutChart = Highcharts.chart('checklistDonutChart', {
      chart: {
          width: 24,
          height: 24,
          borderWidth: 0,
          type: 'pie',
          spacing: [0, 0, 0, 0],
      },
      title: {
        text: ''
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: false,
            size: 24,
        },
        series: {
          enableMouseTracking: false,
        }
      },
      series: [{
        type: 'pie',
        innerSize: '60%',
        data: data,
      }],
      credits: {
        enabled: false
      }
    });
  }

  clickedHideOnboarding() {
    if(this.checksDone == this.maxChecks) {
      this.hideOnboarding();
    } else {
      
      const modal = this.genericDialogService.open(
        {
          title: "Skip onboarding?",
          body: "Completing the onboarding process will ensure you have all the information you need to get started with tracking your first task. Are you sure you want to skip this onboarding?",
          yes: "Yes, skip onboarding",
          no: "Cancel"
        }
      );

      modal.onClose.subscribe(isYes => {
        if(isYes) {
          this.hideOnboarding();
        }
      })

    }
  }

  hideOnboarding() {
    this.onboardingService.setUserSetting({
      onboarding_checklist: {
        hide_onboarding: '1'
      }
    })
      .subscribe(() => {
        this.user.settings.onboarding_checklist.hide_onboarding = '1';
      })
  }

  checkStepsBeforeRelease() {

    this.loading = true;

    // check edit profile
    let checklistReleaseDate: Date = new Date('03/28/2023');
    if(parseISO(this.user.updated_at) < checklistReleaseDate) {
      this.user.settings.onboarding_checklist.edit_profile_done = '1';
    }

    // check start task
    this.user.settings.onboarding_checklist.time_track_done = (this.user.hasTrackedTime) ? '1' : '0';

    this.projectService.getAllProjects()
      .pipe(
        mergeMap(result => {
          const rows: Project[] = result.rows;
  
          rows.forEach(project => {
            
            // check company
            if( project.client_name != 'My Company Name') {
              this.user.settings.onboarding_checklist.create_comp_done = '1';
            }
            
          })
          
          return this.projectService.getActiveProjectsCompact()
        })
      )
      .subscribe(result => {
        let found = false;

        result.forEach(project => {
          project.task_types.forEach(taskType => {
            if(taskType.prefix != '(no category)') {
              this.user.settings.onboarding_checklist.create_proj_cat_done = '1';
              found = true;
              return;
            }
          })
          if(found) {
            return;
          }
        })

        this.loading = false;
        this.refresh(true);
      })
  }

  checkPermissions() {
    if(this.user.active_workspace_userlevel_id >= 2) {
      if(!this.getProgress('create_comp_done' || !this.getProgress('create_proj_cat_done'))) {
        this.user.settings.onboarding_checklist.hide_onboarding = '0';
        this.maxChecks = 4;
      }
    } else {
      this.maxChecks = 2;
    }
  }

  getEarliestStep() {
    if(this.user.settings.onboarding_checklist.edit_profile_done == '0') {
      return 0;
    } else if(this.user.settings.onboarding_checklist.create_comp_done == '0') {
      return 1;
    } else if(this.user.settings.onboarding_checklist.create_proj_cat_done == '0') {
      return 2;
    } else if(this.user.settings.onboarding_checklist.time_track_done == '0') {
      return 3;
    }

    return 0
  }

  getStepCount() {
    let count = 0;
    const isAtLeastManager = this.user.active_workspace_userlevel_id >= 2;

    if(this.user.settings.onboarding_checklist.edit_profile_done == '1') {
      count++;
    }
    if(this.user.settings.onboarding_checklist.create_comp_done == '1' && isAtLeastManager) {
      count++;
    }
    if(this.user.settings.onboarding_checklist.create_proj_cat_done == '1' && isAtLeastManager) {
      count++;
    }
    if(this.user.settings.onboarding_checklist.time_track_done == '1') {
      count++;
    }

    return count;
  }

  getProgress(checklistStep: 'edit_profile_done' | 'create_comp_done' | 'create_proj_cat_done' | 'time_track_done') {
    return this.user.settings.onboarding_checklist[checklistStep] == '1';
  }

  ngOnDestroy() {
    this.updateChecklistSubscription?.unsubscribe();
  }

}
