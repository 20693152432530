import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  router = inject(Router);

  /**
   * Stores the previous page URL the user visited.
   */
  public previousUrl: string = '';
  /**
   * Stores the current page URL the user is viewing.
   */
  public currentUrl: string = '';
  
  private urlSubject = new BehaviorSubject({
    previousUrl: '', 
    currentUrl: ''
  });

  constructor() { 
    
    //Subscription to router events. Unsubscribe is not necessary due to the singleton nature of the service (providedIn: 'root'). This means it is instantiated once and live for the entire duration of the app.
    this.router.events
      .pipe(
        // Filter to capture only NavigationEnd events
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        // Emit the URLs before updating the service variables
        this.urlSubject.next({
          previousUrl: this.currentUrl,
          currentUrl: event.urlAfterRedirects
        });
    
        // Update the service variables for quick access without having to subscribe
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
      });

  }

  /**
   * Method to observe router navigation events and return the current and previous URLs.
   */
  routerEvents$() {
    return this.urlSubject.asObservable().pipe(
      filter(data => data.currentUrl != '')
    );
  }

}
