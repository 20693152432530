import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { Client } from '../models/client';
import { GenericApiResponse } from '../models/genericApiResponse';
import { map } from 'rxjs/operators';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroClientService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  private reloadClientsSubject = new Subject<boolean>();

	reloadCompanies = this.reloadClientsSubject.asObservable();

  triggerReloadClients() {
    this.reloadClientsSubject.next(true);
  }

  constructor(
    private http: HttpClient
  ) { }
  
  /**
   * Create a new client.
   */
  CreateClient(data: any): Observable<Client> {

		return this.http.post<GenericApiResponse>(this.API_BASE + `clients`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful add

            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  getClients(): Observable<Client[]> {
    return this.http.get<GenericApiResponse>(this.API_BASE + `clients`)
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add

            return res.data;

          } else {

            throw new Error(res.message);

          }
        })
      )
  }
  editClient(id: number, data: {}): Observable<Client> {

		return this.http.put<GenericApiResponse>(this.API_BASE + `clients/${id}`, data)
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add
            this.triggerReloadClients();
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }
  archiveClient(id: number): Observable<Client> {

		return this.http.put<GenericApiResponse>(this.API_BASE + `clients/${id}/archive`, {})
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add
            
            this.triggerReloadClients();
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  unarchiveClient(id: number): Observable<Client> {

		return this.http.put<GenericApiResponse>(this.API_BASE + `clients/${id}/unarchive`, {})
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful add
            
            this.triggerReloadClients();
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  deleteClient(id: number): Observable<boolean> {
    
    return this.http.delete<GenericApiResponse>(this.API_BASE + `clients/${id}`)
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful
            
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

}
