import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addBlankOption',
  standalone: true
})
export class ToroAddBlankOptionPipe implements PipeTransform {

  /**
   * Insert a blank option into the top of <p-dropdown> options array
   */
  transform(arrayInput: any[], object: {}): any[] {

    arrayInput.unshift(object);

    return arrayInput;
    
  }

}
