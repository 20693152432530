import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToroMessageBarService {

  constructor() { }

  private refreshMessageBarSubject = new Subject<boolean>();

	refreshMessageBar = this.refreshMessageBarSubject.asObservable();

  refresh() {
    this.refreshMessageBarSubject.next(true);
  }

}
