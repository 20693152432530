import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { format } from 'date-fns';
import { Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Analytics } from '../models/analytics';
import { GenericApiResponse } from '../models/genericApiResponse';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroAnalyticsService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  private timeRangeChangeSubscription = new Subject<'Weekly' | 'Monthly'>();

  timeRangeChange = this.timeRangeChangeSubscription.asObservable();

  private analyticsRefreshSubscription = new Subject<null>();

  analyticsRefresh = this.analyticsRefreshSubscription.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  getAnalytics(workspace_id: number, dateFrom: Date, dateTo: Date): Observable<Analytics> {

    try {
      format(dateFrom, 'yyyy-MM-dd');
      format(dateTo, 'yyyy-MM-dd');
    } catch {
      return throwError('Invalid date range!');
    }

    const stringDateFrom = format(dateFrom, 'yyyy-MM-dd');
    const stringDateTo = format(dateTo, 'yyyy-MM-dd');

    return this.http.get<GenericApiResponse>(this.API_BASE + `analytics?workspace_id=${workspace_id}&date_from=${stringDateFrom}&date_to=${stringDateTo}`)
      .pipe(
        map(res => {
          // console.log('overview', res);
          if(res.status == 'OK') {

            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );
  }

  refreshAnalytics() {
    this.analyticsRefreshSubscription.next(null);
  }

  timeRangeChanged(label: 'Weekly' | 'Monthly') {
    this.timeRangeChangeSubscription.next(label);
  }
}
