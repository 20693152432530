import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiResponse } from '../models/genericApiResponse';
import { ToroDateService } from './date.service';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroInsightsService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
    private dateService: ToroDateService
  ) { }

  /**
   * Get the day tracking insights.
   * @param limit Number of records to return.
   */
  getDayTrackingInsights(date: Date): Observable<string> {

    let timestamp = this.dateService.convertLocalTimeToTimestamp(date);
    let dateInUserTz = this.dateService.convertUtcToUserTimezone(timestamp, 'string', 'yyyy-MM-dd');

    return this.http.get<GenericApiResponse>(this.API_BASE + `insights/day_tracking/${ dateInUserTz }`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

}
