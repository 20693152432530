import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreditCard } from '../models/creditCard';
import { GenericApiResponse } from '../models/genericApiResponse';
import { BillingOverview, StripeSetupIntent, WorkspaceSubscription, WorkspaceSubscriptionSummary } from '../models/billing';
import { Workspace } from '../models/workspace';
import { Invoice } from '../models/invoice';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroBillingService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  //temp storage when navigating between tabs in the detail workspace page
  private activeSubscriptionSubject = new ReplaySubject<BillingOverview>(1);
  
  getActiveSubscription = this.activeSubscriptionSubject.asObservable();
  
  /**
   * Get billing overview for the provided workspace ID. Only workspace admins can call this API endpoint.
   */
  getBillingOverview(workspace_id: number): Observable<BillingOverview> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `billing/overview/${workspace_id}`)
      .pipe(
        map(res => {
          // console.log('overview', res);
          if(res.status == 'OK') {
           
            this.activeSubscriptionSubject.next(res.data);
            
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }
  /**
   * Get subscription overview for the user active workspace. Only workspace admins can call this API endpoint.
   */
  getActiveWorkspaceSubscriptionOverview(): Observable<WorkspaceSubscriptionSummary> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `billing/active_workspace_subscription_summary`)
      .pipe(
        map(res => {
          // console.log('active workspace sub overview', res);
          if(res.status == 'OK') {
            
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Get the list of credit cards for the provided workspace ID. Only workspace owners can call this API endpoint.
   */
  getCards(workspace_id: number): Observable<CreditCard[]> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `billing/payment_methods/${workspace_id}`)
      .pipe(
        map(res => {
          // console.log('cards', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Get the list of invoices for the provided workspace ID. Only workspace owners can call this API endpoint.
   */
  getInvoices(workspace_id: number): Observable<Invoice[] >  {

    return this.http.get<GenericApiResponse>(this.API_BASE + `billing/invoices/${workspace_id}`)
      .pipe(
        map(res => {
          // console.log('invoices', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Get StripeSetupIntent to save the customer payment method.
   */
  getSetupIntent(workspace_id: number): Observable<StripeSetupIntent> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `billing/intent/${workspace_id}`)
      .pipe(
        map(res => {
          
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Submit the order to API for creating a new subscription for a workspace using the provided payment_method.
   */
  createSubscription(stripeProductPriceId: string, stripePaymentMethodId: string, workspace_id: number, isExistingCard: boolean = false): Observable<{
    subscription: WorkspaceSubscription,
    workspace: Workspace
  }> {

    let data = {
      'plan_id': stripeProductPriceId,
      'payment_method': stripePaymentMethodId,
      'workspace_id': workspace_id,
      'existing': isExistingCard
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/create`, data)
      .pipe(
        map(res => {
          // console.log('create subs', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Update the workspace subscription billing address.
   */
  updateBillingAddress(billingAddressId: number, data: {}): Observable<any> {

    return this.http.put<GenericApiResponse>(this.API_BASE + `billing/address/${billingAddressId}`, data)
      .pipe(
        map(res => {
          // console.log('update billing address', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );
    
  }

  /**
   * Add a new credit card.
   */
  addCard(stripePaymentMethodId: string, workspace_id: number): Observable<CreditCard> {

    let data = {
      'payment_method': stripePaymentMethodId,
      'workspace_id': workspace_id,
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/card/`, data)
      .pipe(
        map(res => {
          // console.log('add card', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );
    
  }

  /**
   * Set a credit card as the default for the workspace subscription billing.
   */
  setDefaultCard(paymentMethodId: number, workspace_id: number): Observable<CreditCard> {

    let data = {
      'payment_method': paymentMethodId,
      'workspace_id': workspace_id,
    };

    return this.http.put<GenericApiResponse>(this.API_BASE + `billing/default_payment_method`, data)
      .pipe(
        map(res => {
          // console.log('set default card', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );
    
  }

  /**
   * Delete an existing card
   */
  deleteCard(paymentMethodId: number, workspace_id: number): Observable<CreditCard> {

    let data = {
      'payment_method': paymentMethodId,
      'workspace_id': workspace_id,
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/card/delete`, data)
      .pipe(
        map(res => {
          // console.log('delete card', res);
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );
    
  }

  /**
   * Cancel an active subscription
   */
  cancelSubscription(workspace_id: number): Observable<boolean> {

    let data = {
      'workspace_id': workspace_id,
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/cancel`, data)
      .pipe(
        map(res => {
          // console.log('cancel sub', res); 
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Resume a cancelled subscription that is still in grace period.
   */
   resumeSubscription(workspace_id: number): Observable<boolean> {

    let data = {
      'workspace_id': workspace_id,
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/resume`, data)
      .pipe(
        map(res => {
          // console.log('resume sub', res); 
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Swap workspace subscription price between monthly and annually.
   */
   swapSubscriptionPrice(workspace_id: number, stripe_price: string): Observable<boolean> {

    let data = {
      'workspace_id': workspace_id,
      'price': stripe_price,
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `billing/swap`, data)
      .pipe(
        map(res => {
          // console.log('swap sub', res); 
          if(res.status == 'OK') {
           
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

}
