import { Pipe, PipeTransform } from '@angular/core';
import { ToroArrayService } from '../services/array.service';

@Pipe({
  name: 'filterArray',
  standalone: true
})
export class ToroFilterArrayPipe implements PipeTransform {

  constructor(
    private arrayService: ToroArrayService
  ) { }

  /**
   * Filter an array by a specific key-value.
   */
  transform(arrayInput: any[], key: string, value: any): any[] {
    return this.arrayService.filterArray(arrayInput, key, value);
  }

}
