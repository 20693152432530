import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { addDays, isBefore, parse, format } from 'date-fns';
import { GenericApiResponse } from '../models/genericApiResponse';
import { TimeTrackingFormData, TimeTracking, RecentTimeTracking } from '../models/timeTracking';
import { ToroDateService } from './date.service';
import { TaskType } from '../models/tasktype';
import { ClientProjectsTaskTypes } from '../models/project';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroTimerService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  timerMode: 'timer' | 'manual' = 'timer';

  private timerSubject = new BehaviorSubject<TimeTracking | TimeTrackingFormData | null>(null);

  timerState = this.timerSubject.asObservable();

  private resumeTimeTrackingSubject = new Subject<TimeTrackingFormData>();

  resumeTimeTracking = this.resumeTimeTrackingSubject.asObservable();

  private deleteTimeTrackingSubject = new Subject<number>();

  deleteTimeTrackingEvent = this.deleteTimeTrackingSubject.asObservable();

  private toggleEditTimeTrackingSubject = new Subject<number>();

  toggleEditTimeTrackingEvent = this.toggleEditTimeTrackingSubject.asObservable();

  private reloadRecentTimeTrackingSubject = new Subject<boolean>();

  reloadRecentTimeTracking = this.reloadRecentTimeTrackingSubject.asObservable();

  private onboardingFinishedSubject = new Subject<null>();

  onboardingFinished = this.onboardingFinishedSubject.asObservable();

  // keep global track of google row tooltip to prevent duplicates
  googleRowTooltipAlreadyActive: boolean = false;

  constructor(
    private http: HttpClient,
    private dateService: ToroDateService
  ) { }

  /**
   * Save a new time tracking entry.
   * @returns Saved time tracking data
   */
  saveTimeTracking(data: TimeTrackingFormData, reloadRecentTimeTracking: boolean): Observable<TimeTracking>  {

    let currentTime: Date = new Date();

    if(data.name == '') {
      //if blank, set default value
      data.name = '-';
    }

    data.start_time = <string>this.dateService.cleanDateStringToDatabaseFormat(<string>data.start_time);
    data.end_time = <string>this.dateService.cleanDateStringToDatabaseFormat(<string>data.end_time);

    if(data.end_time.length > 0) {
      //if end_time is not blank, check if end_time is less than start_time. If yes, convert the end_time date to the next day because we consider the end_time is crossing a day.

      //validate and convert the strings to Date object
      let start_date_time = parse(data.start_time, 'yyyy-MM-dd HH:mm:ss', currentTime);
      let end_date_time = parse(data.end_time, 'yyyy-MM-dd HH:mm:ss', currentTime);

      if( isBefore(end_date_time, start_date_time) ) {
        end_date_time = addDays(end_date_time, 1);
        data.end_time = format(end_date_time, 'yyyy-MM-dd HH:mm:ss');
      }
    } else {
      //if blank, delete property
      delete data.end_time;
    }

    return this.http.post<GenericApiResponse>(this.API_BASE + `tasks`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {

            if(reloadRecentTimeTracking) {
              this.reloadRecentTimeTrackingSubject.next(true);
            }

            return res.data;

          } else {

            let message = res.message;

            if(res.message == 'Already started') {

              let start_time = <Date>this.dateService.convertUtcToUserTimezone(res.data.start_time)

              message = `You have an active time tracking titled “${res.data.name}” that has been running since ${format(start_time, 'MMM do p')}. Please refresh the page to view it.`;

            } else if(res.message == 'No project found.') {

              message = `Unable to save because the selected project has been deleted or reassigned. Please refresh the page to see the updated data.`;

            } else if(res.message == 'No task category found.') {

              message = `Unable to save because the selected task category has been deleted or reassigned. Please refresh the page to see the updated data.`;

            }

            throw new Error(message);

          }

        })
      );

  }

  /**
   * Update an existing time tracking entry by id.
   * @returns Saved time tracking data
   */
  updateTimeTracking(id: number, data: TimeTrackingFormData, reloadRecentTimeTracking: boolean): Observable<TimeTracking> {

    let currentTime: Date = new Date();

    if(data.name == '') {
      //if blank, set default value
      data.name = '-';
    }

    data.start_time = <string>this.dateService.cleanDateStringToDatabaseFormat(<string>data.start_time);
    data.end_time = <string>this.dateService.cleanDateStringToDatabaseFormat(<string>data.end_time);

    if(data.end_time.length > 0) {
      //if end_time is not blank, check if end_time is less than start_time. If yes, convert the end_time date to the next day because we consider the end_time is crossing a day.

      //validate and convert the strings to Date object
      let start_date_time = parse(data.start_time, 'yyyy-MM-dd HH:mm:ss', currentTime);
      let end_date_time = parse(data.end_time, 'yyyy-MM-dd HH:mm:ss', currentTime);

      if( isBefore(end_date_time, start_date_time) ) {
        end_date_time = addDays(end_date_time, 1);
        data.end_time = format(end_date_time, 'yyyy-MM-dd HH:mm:ss');
      }
    } else {
      //if blank, delete property
      delete data.end_time;
    }

    if(data.start_time.length > 0) {
    } else {
      //if blank, delete property
      delete data.start_time;
    }

    return this.http.put<GenericApiResponse>(this.API_BASE + `tasks/${id}`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {

            if(reloadRecentTimeTracking) {
              this.reloadRecentTimeTrackingSubject.next(true);
            }

            return res.data;

          } else {

            let message = res.message;

            if(res.message == 'Already stopped') {

              let end_time = <Date>this.dateService.convertUtcToUserTimezone(res.data.end_time)

              message = `This particular time tracking has already been stopped at ${format(end_time, 'MMM do p')}. Please refresh the page to clear it.`;

            } else if(res.message == 'No project found.') {

              message = `Unable to save because the selected project has been deleted or reassigned. Please refresh the page to see the updated data.`;

            } else if(res.message == 'No task category found.') {

              message = `Unable to save because the selected task category has been deleted or reassigned. Please refresh the page to see the updated data.`;

            }

            throw new Error(message);

          }

        })
      );

  }

  /**
   * Emit the signal to start the timer based on the provided time tracking object.
   * @param timetracking New or existing time tracking object.
   */
  startTimer(timetracking: TimeTracking | TimeTrackingFormData) {
    this.timerSubject.next(timetracking);
  }

  /**
   * Emit the signal to resume the timer based on the provided time tracking object. This is called from the Resume button in the time tracking history tables.
   * @param timetracking Time tracking object.
   */
  resumeTimer(timetracking: TimeTrackingFormData) {
    this.resumeTimeTrackingSubject.next(timetracking);
  }

  /**
   * Emit the signal to stop the timer.
   */
   stopTimer() {
    this.timerSubject.next(null);
  }

  /**
   * Get recent time tracking entries up to the specified number of records including daily diary entries.
   * @param limit Number of records to return.
   */
  getRecentTimeTracking(limit: number = 50): Observable<RecentTimeTracking> {

    let currentTime: Date = new Date();

    return this.http.get<GenericApiResponse>(this.API_BASE + `tasks/${ format(currentTime, 'yyyy-MM-dd') }/limit/${limit}`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  /**
   * Get active (running) time tracking.
   * @param limit Number of records to return.
   */
  getActiveTimeTracking(): Observable<TimeTracking> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `tasks`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  /**
   * Delete a time tracking record.
   * @param id Time tracking record id.
   * @returns
   */
  deleteTimeTracking(id: number): Observable<boolean> {

    return this.http.delete<GenericApiResponse>(this.API_BASE + `tasks/${id}`)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful

            this.deleteTimeTrackingSubject.next(id);

            return true;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  /**
   * Emit signal to enter edit mode for a particular time tracking and will cause other time tracking to exit from edit mode.
   * @param id Time tracking id.
   */
  toggleEditTimeTrackingMode(id: number) {
    this.toggleEditTimeTrackingSubject.next(id);
  }

  /**
   * Method with the sole purpose of populating the task category dropdown when changing the project dropdown.
   * @param project Project object containing task_types array.
   * @returns
   */
  populateTaskTypesDropdown(project: ClientProjectsTaskTypes | undefined): TaskType[] {

    let taskTypes: TaskType[] = [];

    if(!project || project.id.toString() == '') {

      taskTypes.push( { id: '', prefix: 'No category' } as any );

    } else {

      if(project.task_types) {
        taskTypes = <TaskType[]>project?.task_types
      } else {
        taskTypes.push( { id: '', prefix: 'No category' } as any );
      }

    }

    return taskTypes;

  }

  reloadRecentTimeTrackingData() {
    this.reloadRecentTimeTrackingSubject.next(true);
  }

  finishedOnboarding() {
    this.onboardingFinishedSubject.next(null);
  }

}
