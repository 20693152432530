import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
import { WorkspaceUser } from '../models/workspace';

@Pipe({
  name: 'filterUsers',
  standalone: true
})
@Injectable({
  providedIn: 'root'
})
export class ToroFilterUsersPipe implements PipeTransform {

  transform(users: User[], pivot: boolean, filterKey: keyof User | keyof WorkspaceUser, filterValue: any): User[] {

    let filtered_users = users.filter(user => {

      if(pivot) {
        return user.pivot[<keyof WorkspaceUser>filterKey] == filterValue;
      } else {
        return user[<keyof User>filterKey] == filterValue;
      }

    });

    // console.log(filtered_users.length);
    
    return filtered_users;
  }

}
