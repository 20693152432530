import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
import { ToroWorkspaceUserService } from '../services/workspace-user.service';

@Pipe({
  name: 'workspaceUserData',
  standalone: true
})
export class ToroWorkspaceUserDataPipe implements PipeTransform {

  constructor(
    private workspaceUserService: ToroWorkspaceUserService
  ) {}

  transform(value: number | string, key: string, user?: User): string {

    let return_value: string = '';

    switch(key) {

      case 'first_name':
        if(user?.pivot.first_name) {
          return_value = user.pivot.first_name;
        } else {
          return_value = <string>value;
        }
      break;

      case 'last_name':
        if(user?.pivot.first_name) {
          return_value = user.pivot.last_name;
        } else {
          return_value = <string>value;
        }
      break;

      case 'email':
        if(user?.pivot.email) {
          return_value = user.pivot.email;
        } else {
          return_value = <string>value;
        }
      break;

      case 'userlevel_id':
        
        let user_level = this.workspaceUserService.workspaceUserLevels.find(level => {
          return level.userlevel_id == value
        });

        if(user_level) {
          return_value = user_level.label;
        }

      break;

      case 'status':
        
        if(value == 1) {
          return_value = 'Active';
        } else if(value == 0 || value == 2) {
          return_value = 'Deactivated';
        }

      break;

      case 'jobrole':
        
        if(value) {
          return_value = <string>value;
        } else {
          return_value = 'Not specified';
        }

      break;

    }

    return return_value;
  }

}
