import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiResponse } from '../models/genericApiResponse';
import { GoogleCalendar } from '../models/integration_google';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroGoogleService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  /** Handles the OAuth connection process. */
  connect(code?: string): Observable<string> {

    let codeQueryString;

    if(code) {
      codeQueryString = `code=${code}`;
    }

    return this.http.get<GenericApiResponse>(this.API_BASE + `google/connect?${codeQueryString}`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  /** Sign out from the user's Google account */
  disconnect(): Observable<boolean> {

    return this.http.post<GenericApiResponse>(this.API_BASE + `google/disconnect`, {})
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return true;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  getCalendars(): Observable<GoogleCalendar[]> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `google/calendars`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  saveCalendars(data: {
    workspace_id: number,
    selectedCalendars: any[]
  }): Observable<boolean> {

    return this.http.post<GenericApiResponse>(this.API_BASE + `google/calendars`, data)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

}
