
<!-- Timer onboarding -->
<swiper
  #swiper
  [config]="swiperConfig"
  *ngIf="name == 'Timer'"
  (slideChange)="onSlideChange($event)"
  [ngClass]="{
    'welcome' : this.currentStep == 0,
    'last' : this.currentStep == 3
  }"
>
  <!-- Page 1 -->
  <ng-template swiperSlide>
    <div class="modal-container step1">
      <div class="img-block">
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
        <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
        <img class="img" src="./assets/images/onboarding/step2.svg" alt="Let's track your first task ⏱">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Got a task? Track it!</h3>
          <p>Easily track the task you're working on using the timer or manual mode. You can also assign the task to specific <strong>Project</strong> and <strong>Category</strong> for easy organisation.</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next: Sync your meeting</a>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Page 2 -->
  <ng-template swiperSlide>
    <div class="modal-container step2">
      <div class="img-block">
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
        <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
        <img class="img" src="./assets/images/onboarding/step3.svg" alt="Sync your meetings">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Sync your meetings</h3>
          <p>Connect to <strong>Google Calendar</strong> and your meetings will now appear in Toro, ready for tracking. Remember to protect that Focus Window from stray meetings!</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-link btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next: Visualise your day</a>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Page 3 -->
  <ng-template swiperSlide>
    <div class="modal-container step3">
      <div class="img-block">
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
        <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
        <img class="img" src="./assets/images/onboarding/step4.svg" alt="Your day, visualised">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Your day, visualised</h3>
          <p>Toro paints a picture of how you spend your day. Hover on each block on your timebar to see what you worked on. Feeling festive? <strong>Personalise your timebar</strong> with colours!</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-link btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">All set?</a>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Page 4 -->
  <ng-template swiperSlide>
    <div class="modal-container step4">
      <div class="img-block">
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
        <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
        <img class="img" src="./assets/images/onboarding/step5.svg" alt="Your day, visualised">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>And we have a lift off!</h3>
          <p>Share your feedback anytime by clicking on the Support bubble. Thank you for using Toro Timer!</p>
          <a class="btn-sd btn-green" (click)="close(); dontShowAnymore(true);">Start tracking</a>
        </div>
      </div>
    </div>
  </ng-template>

</swiper>

<!-- Project onboarding -->
<div *ngIf="name == 'Project'" class="modal-container step-project">
  <div class="img-block">
    <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
    <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
    <img class="img" src="./assets/images/onboarding/project.svg" alt="Project & Company">
  </div>
  <div class="modal-text">
    <div class="modal-content">
      <h3>Project & Company</h3>
      <p>Categorise your time spent by assigning tasks to a specific project or company. New project? Click on the “Add new...” button for options.</p>
      <a class="btn-sd btn-green" (click)="close()">Create new project</a>
      <p><small>Working with a client? <a (click)="close(false, true, true)">Create a new company</a></small></p>
    </div>
  </div>
</div>

<!-- Google Integration onboarding -->
<div *ngIf="name == 'Google Calendar'" class="modal-container step-calendar">
  <div class="img-block">
    <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
    <img class="bg" src="./assets/images/onboarding/bg.svg" alt="">
    <img class="img" src="./assets/images/onboarding/calendar.svg" alt="Say goodbye to tracking meetings manually!">
  </div>
  <div class="modal-text">
    <div class="modal-content">
      <h3>Say goodbye to tracking meetings manually!</h3>
      <p>Google Calendar is now connected to Toro. Head over to Track page to see today's meetings synced on your timebar.</p>
      <p>P/s: Future meetings will sync every 15 mins</p>
      <a class="btn-sd btn-green" (click)="close()">Take me to Track page</a>
    </div>
  </div>
</div>

<!-- Dock onboarding -->
<div *ngIf="name == 'Dock'" class="modal-container step-dock">
  <div class="img-block">
    <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close(true)"></p-button>
    <img class="bg" src="./assets/images/onboarding/bg-alt.svg" alt="">
    <img class="img" src="./assets/images/onboarding/dock.svg" alt="Make Toro a shortcut">
  </div>
  <div class="modal-text">
    <div class="modal-content">
      <h3>Make Toro a shortcut</h3>
      <p>In Chrome, click <span class="icon-dots"></span> > More Tools > Create Shortcut. Check “Open as Window” and press “Create”.</p>
      <p>Now Toro is just a click away!</p>
      <p><small>Using another browser? <a href="https://torotimer.com/support/tips-and-tutorials/create-a-shortcut-for-toro/" target="_blank">Step-by-step guide here</a></small></p>
    </div>
  </div>
</div>
