import { Pipe, PipeTransform } from '@angular/core';
import { differenceInHours, isBefore, isSameDay, addDays } from 'date-fns';
import { ToroDateService } from '../services/date.service';

@Pipe({
  name: 'daysFromToday',
  standalone: true
})
export class ToroDaysFromTodayPipe implements PipeTransform {

  constructor(
    private dateService: ToroDateService
  ) { }
  
  transform(dateStringUtc: string, addDaysToInput?: number): number {

    let difference;

    let currentTime = <Date>this.dateService.convertUtcToUserTimezone( Date.now(), 'Date' );

    let inputTime = <Date>this.dateService.convertUtcToUserTimezone( dateStringUtc, 'Date' );

    if(addDaysToInput) {
      inputTime = addDays(inputTime, addDaysToInput);
    }

    if( isBefore(currentTime, inputTime) ) {

      let differenceHours = differenceInHours(inputTime, currentTime);

      if(differenceHours < 24) {
        
        if(isSameDay(currentTime, inputTime)) {
          difference = 0;
        } else {
          difference = 1;
        }

      } else {
        difference = Math.ceil(differenceHours / 24);
      }


    } else {
      //inputTime is behind currentTime
      difference = 0;
    }
    
    // console.log(inputTime, currentTime, difference);

    return difference;

  }

}
