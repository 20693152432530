import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHour',
  standalone: true
})
export class ToroSecondsToHourPipe implements PipeTransform {

  transform(seconds: number, type: string, decimalSuffix: string = ' h', decimalThousandsCommaSeparator: boolean = true): number | string {

    if(type == 'decimal') {
      //"Decimal" format

      let hour = seconds / 3600;
      let inDecimal = hour.toFixed(2);

      if(decimalThousandsCommaSeparator) {
        inDecimal = formatNumber(parseFloat(inDecimal), 'en-US');
      }

      if(decimalSuffix.length) {
        return inDecimal + decimalSuffix;
      } else {
        return parseFloat(inDecimal);
      }

    } else if(type == 'classic') {
      //"Classic" format
      
      try {

        let hours_float = seconds / 3600;

        let hours = Math.floor(hours_float);

        let remaining_seconds = seconds - (hours * 3600);

        let minutes_float = remaining_seconds / 60;

        let minutes = Math.floor(minutes_float);
        
        remaining_seconds = remaining_seconds - (minutes * 60);

        // console.log(seconds, hours, minutes, remaining_seconds );

        let dur_hours = '00', dur_minutes = '00', dur_seconds = '00';

        //check if we need to prepend 0 to the duration values
        dur_hours = (hours < 10)? `0${hours}` : hours.toString();
        dur_minutes = (minutes < 10)? `0${minutes}` : minutes.toString();
        dur_seconds = (remaining_seconds < 10)? `0${remaining_seconds}` : remaining_seconds.toString();
      
        return `${dur_hours}:${dur_minutes}:${dur_seconds}`;

      } catch(e) {
        return '';
      }

    } else if(type == 'classic-v2') {
      /*
      "Classic" format that may show up like:
      12 h 42 m 
      5 m 30 s
      30 s
      */

      try {

        let hours_float = seconds / 3600;

        let hours = Math.floor(hours_float);

        let remaining_seconds = seconds - (hours * 3600);

        let minutes_float = remaining_seconds / 60;

        let minutes = Math.floor(minutes_float);
        
        remaining_seconds = remaining_seconds - (minutes * 60);

        // console.log(seconds, hours, minutes, remaining_seconds );

        let dur_hours = '0', dur_minutes = '0', dur_seconds = '0';

        //convert numbers to strings
        dur_hours = hours.toString();
        dur_minutes = minutes.toString();
        dur_seconds = remaining_seconds.toString();
        
        //if time tracking is less than a minute, show seconds only
        if(hours == 0 && minutes == 0) {
          return `${dur_seconds} s`;
        } else if(hours == 0) {
          return `${dur_minutes} m ${dur_seconds} s`;
        } else {
          return `${dur_hours} h ${dur_minutes} m`;
        }

      } catch(e) {
        return '';
      }

    }
    
    return '';

  }

}
