import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MixpanelService } from './mixpanel.service';
import { ToroTimerService, ToroLoadingService, TimeTracking } from 'toro-lib';

interface PushNotificationClickPayload {
  action: 'save-task';
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationHandlerService implements OnDestroy {

  queryParamsSubscription: Subscription;

  constructor(
    private timerService: ToroTimerService,
    private mixpanelService: MixpanelService,
    private loadingService: ToroLoadingService,
    private route: ActivatedRoute,
    private readonly ngZone: NgZone
  ) { 

    //Handles web push actions (wpa) from URL query string such as when clicking "Save tracked task" CTA in push notification while not having any Toro browser tab opened, so it should launch a new Toro tab with a ?wpa=base64Encodeddata URL query string.
    this.queryParamsSubscription = this.route.queryParams
      .subscribe(params => {

        if(params?.wpa) {
          this.handleAction(params.wpa)
        }

      });
    
  }

  /**
   * Determines what happens after clicking the web push CTA based on the provided base64 payload.
   */
  handleAction(encodedPayload: string) {

    //decode data
    const payload = JSON.parse( atob(encodedPayload) ) as PushNotificationClickPayload;

    switch(payload.action) {

      case 'save-task':

        //construct the payload for API
        const data: Partial<TimeTracking> = {
          id: payload?.taskId,
          name: payload?.taskName,
          is_billable: payload?.isBillable,
        };

        this.saveTimeTracking(data);

      break;
      
    }

  }

  /**
   * Save time tracking triggered by click event from "Save tracked task" CTA in push notification.
   */
  saveTimeTracking(data: Partial<TimeTracking>) {

    this.ngZone.run(()=> {

      this.loadingService.show();

      this.mixpanelService.track('Save time tracking from push notification');

      this.timerService.updateTimeTracking(data.id as number, data as TimeTracking, true)
        .subscribe(
          data => {

              setTimeout(() => {
                //additional loading delay for refreshing time tracking listing
                this.loadingService.hide();
              }, 2000);
              
            },
            err => {
              this.loadingService.hide();
            }
          );
          
    });
  }

  ngOnDestroy() {
    this.queryParamsSubscription?.unsubscribe();
  }

}
