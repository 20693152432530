import { Pipe, PipeTransform } from '@angular/core';
import {TimeTrackingFormData} from '../models/timeTracking';

@Pipe({
  name: 'dynamicToolTip',
  standalone: true
})
export class ToroDynamicToolTipPipe implements PipeTransform {

  transform(billable: boolean | 1 | 0 = false , tooltip: string) {

    if(billable === 1 || billable != false) {
      tooltip = 'Billable';
    } else  {
      tooltip = 'Non-billable';
    }
    return tooltip;
  }
}
