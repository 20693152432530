import { Pipe, PipeTransform } from '@angular/core';
import {Project} from './../models/project';

@Pipe({
  name: 'location',
  standalone: true
})
export class ToroLocationPipe implements PipeTransform {

  transform(contributors: Project["contributors"]) {

    let loc: string = '';

    let data = contributors.filter(elem => elem.location !== null).length;

    if(data != 0) {

      if(data === 1) {
        loc = data + ' location'
      } else {
        loc = data + ' locations'
      }

    }
    return loc;
  }


}
