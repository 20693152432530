import { Pipe, PipeTransform } from '@angular/core';
import { WorkingDaysParam } from '../models/user';

@Pipe({
  name: 'displayWorkingDays',
  standalone: true
})
export class ToroDisplayWorkingDaysPipe implements PipeTransform {
  dayLists: WorkingDaysParam[] = [
    { label: 'Mon', value: "1" },
    { label: 'Tue', value: "2" },
    { label: 'Wed', value: "3" },
    { label: 'Thu', value: "4" },
    { label: 'Fri', value: "5" },
    { label: 'Sat', value: "6" },
    { label: 'Sun', value: "7" },
  ]
  transform(selectedDays: string[]): string {

    const daysArray = selectedDays.map(dayIndex => {
      const dayObj = this.dayLists.find(day => day.value == dayIndex);
      return dayObj?.label;
    });

    return daysArray.join(', ');
    
  }
}