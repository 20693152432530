import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToroStringService {

  /**
   * Return the value of a parameter from a URL query string. For example extractParameterFromUrl('https://www.test.com?param=123', 'param') will return 123.
   */
  extractParameterFromUrl(url: string, key: string): string {
    
    let value!: string;

    // get query string from url (optional) or window
    let queryString = url.split('?')[1]? url.split('?')[1]: url.split('?')[0];

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      const searchKey = `${key}=`;

      //look for the key and get its value after the = sign
      value = queryString.substring( queryString.indexOf(searchKey) + searchKey.length);

      //clean up the value
      value = value.split('&')[0];
      
    }
    
    return value;

  }
  
}
