import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericApiResponse } from '../models/genericApiResponse';
import { PublicReport } from '../models/public_report';
import { map } from 'rxjs/operators';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroPublicReportService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getDataFromHash(hash: string, params: {}): Observable<PublicReport> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `public/` + hash, {
      params: params
    })
      .pipe(
        map((res) => {

          if(res.status == 'OK') {

            return res.data;

          } else {

            throw new Error(res.message);

          }
        })
      );

  }

}
