import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import{ Project } from '../models/project';
import { GenericApiResponse, PaginatedData } from '../models/genericApiResponse';
import { TaskType } from '../models/tasktype';
import { ClientProjectsTaskTypes } from '../models/project';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroProjectService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  private reloadProjectSubject = new BehaviorSubject<boolean>(false);

	reloadProject = this.reloadProjectSubject.asObservable();

  private onboardingFinishedSubject = new Subject<'' | 'project' | 'company'>();

	onboardingFinished = this.onboardingFinishedSubject.asObservable();

  colorSwatches = [
    // Ice Cube
    ['#E4F5F1', '#CAECE3', '#AFE2D5', '#75978E', '#3A4B47'],

    // Grass Green
    ['#BDDDAF', '#7ABA60', '#389810', '#25650B', '#133305'],

    // Butternut Squash
    ['#FDD1AA', '#FBA455', '#F97600', '#A64F00', '#532700'],

    // Fluorescent Orange
    ['#FFEFAA', '#FEDE55', '#FECE00', '#A98900', '#554500'],

    // Early Sprint Night
    ['#C0C0E6', '#8282CE', '#4343B5', '#2D2D79', '#16163C'],

    // Diva
    ['#EBE1FD', '#D6C3FB', '#C2A5F9', '#816EA6', '#413753'],

    // Hot Aquarelle Pink
    ['#FFE6F3', '#FFCCE6', '#FFB3DA', '#AA7791', '#553C49'],

    // Sora Blue
    ['#DEF4FB', '#BEE9F8', '#9DDEF4', '#6994A3', '#344A51'],

    // Hadfield Blue
    ['#B1CFFF', '#63A0FF', '#1570FF', '#0E4BAA', '#072555']
  ]

  triggerReloadProject() {
    this.reloadProjectSubject.next(true);
  }

  //get all projects
  getAllProjects(query_string: string = '', forceReload: boolean = false): Observable<PaginatedData>{

    // if(forceReload) {
    //   this.manager.delete(this.projectCacheBucket)
    // }

    return this.http.get<GenericApiResponse>(this.API_BASE + `projects?${query_string}`)
    .pipe(

      map(res => {
        //console.log('project response ', res);
        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })
    );
  }

  /**
   * Get a complete list of active projects in a compact form and structure that is easy to populate into the Timer page Projects and Task Category dropdowns.
   */
  getActiveProjectsCompact(): Observable<ClientProjectsTaskTypes[]>{

    return this.http.get<GenericApiResponse>(this.API_BASE + `projects_timer`)
    .pipe(

      map(res => {

        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })
    );
  }

  createProjectInCompany(data: any): Observable<Project>  {
    return this.http.post<GenericApiResponse>(this.API_BASE + `projects`, data)
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })
    );
  }


  updateProject(projectId: number, data: any): Observable<Project> {
    return this.http.put<GenericApiResponse>(this.API_BASE + `projects/${projectId}`, data)
    .pipe(

      map(res => {

        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })

    );
  }

  //get project templates
  getProjectTemplates(){
    return this.http.get<GenericApiResponse>(this.API_BASE + `projects/templates`)
    .pipe(
      map(res => {
        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }
      })
    )
  }

  archiveProject(id: number): Observable<Project> {
    return this.http.put<GenericApiResponse>(this.API_BASE + `projects/${id}/archive`, {})
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })
    )
  }

  unArchiveProject(id: number): Observable<Project> {
    return this.http.put<GenericApiResponse>(this.API_BASE + `projects/${id}/unarchive`, {})
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }

      })
    )
  }

  getProjectDetailByID(id: number, forceReload: boolean = false): Observable<Project> {
    return this.http.get<GenericApiResponse>(this.API_BASE + `projects/` + id)
    .pipe(
      map(res => {
        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }
      })
    )
  }

  getTaskTypesUnderProject(id: number): Observable<TaskType[]> {
    return this.http.get<GenericApiResponse>(this.API_BASE + `projects/${id}/tasktypes`)
    .pipe(
      map(res => {
        if(res.status == 'OK') {
          return res.data;
        } else {
          throw new Error(res.message);
        }
      })
    )
  }

  deleteProject(id: number, reassignToProjectId?: number): Observable<boolean> {

    let data: any = {
      'reassign_to_project_id': reassignToProjectId
    };
    
    return this.http.delete<GenericApiResponse>(this.API_BASE + `projects/${id}`, {
        params: data
      })
      .pipe(
        map(res => {
          if(res.status == 'OK') {
            //successful
            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  finishedProjectOnboarding(closeType: 'project' | 'company') {
    this.onboardingFinishedSubject.next(closeType);
  }

  getColorSwatchCode(): string {
    const colorGroup = Math.round(Math.random() * (this.colorSwatches.length-1));
    const colorCode = Math.round(Math.random() * 4);
    return this.colorSwatches[colorGroup][colorCode]
  }

}
