import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiResponse } from '../models/genericApiResponse';
import { Diary } from '../models/diary';
import { ToroDateService } from './date.service';
import { addHours, startOfDay } from 'date-fns';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroDiaryService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
    private dateService: ToroDateService,
  ) { }

  /**
   * Get diary entry based on the provided date.
   * @param date Date in format yyyy-mm-dd
   * @returns Diary entry.
   */
  getEntry(date: string): Observable<Diary> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `diary/${date}`)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  /**
   * Add a new diary entry or update diary entry of the specified date.
   * @param data Diary data.
   * @returns Diary entry.
   */
   saveEntry(data: Diary): Observable<Diary> {

    return this.http.post<GenericApiResponse>(this.API_BASE + `diary`, data)
      .pipe(

        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }

        })
      );

  }

  /**
   * Check if a date has any diary entry.
   * @param recentDiaryEntries Array of recent diary entry dates.
   * @param date Date time in user timezone.
   * @returns 
   */
  checkDiaryEntryExists(recentDiaryEntries: Diary[], date: Date): boolean {

    //set given date to 12:00:00 time
    let dateTimeStartOfDay = addHours(startOfDay(date), 12);
    
    //convert it to UTC
    let dateTimeStartOfDayUtc = this.dateService.convertUserTimezoneToUtc( dateTimeStartOfDay.getTime() );

    //get the date part only
    let dateStartOfDayUtc = dateTimeStartOfDayUtc.split('T')[0];

    //check if exists
    return recentDiaryEntries.some(entry => {
      return entry.for_date == dateStartOfDayUtc;
    });
    
  }

}
