import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStripeAmount',
  standalone: true
})
export class ToroFormatStripeAmountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {

    let amount =  value / 100;

    return formatNumber(amount, 'en-US', '1.2-2');
    
  }

}
