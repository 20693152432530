import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToroRecentTimeTrackedBoxesService {

  constructor() { }

  //for keeping track of opened Recent Time Tracked boxes in Timeline mode
  boxesState: Set<string> = new Set();

  //for keeping track of Recent Time Tracked view mode
  viewMode: 'timeline' | 'listingonly' = 'timeline';

}
