import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { ToastMessageService } from 'src/app/services/toast-message.service';
import { Timezone, ToroChecklistService, ToroDataService, ToroLoadingService, ToroUserService, User } from 'toro-lib';

@Component({
  selector: 'app-dialog-edit-profile',
  templateUrl: './dialog-edit-profile.component.html',
  styleUrls: ['./dialog-edit-profile.component.scss']
})
export class DialogEditProfileComponent implements OnInit {

  constructor(
    private ref: DynamicDialogRef, 
    private config: DynamicDialogConfig,
    private fb: UntypedFormBuilder,
    private loadingService: ToroLoadingService,
    private userService: ToroUserService,
    private toastMessageService: ToastMessageService,
    private http: HttpClient,
    private mixpanelService: MixpanelService,
    private checklistService: ToroChecklistService,
    private dataService: ToroDataService
  ) { 
    this.user = this.config.data.user;
  }

  myForm = this.fb.group({
    first_name: ['' , [
      Validators.required,
      Validators.maxLength(64)
    ]],
    last_name: ['' , [
      Validators.required,
      Validators.maxLength(64)
    ]],
    jobrole: ['', [
      Validators.maxLength(64)
    ]],
    location: ['', [
      Validators.maxLength(64)
    ]],
    timezone: ['' , [
      Validators.required,
      Validators.maxLength(64)
    ]],
    avatar: ['']
  });
  
  loading: boolean = false;
  timezones!: Timezone[];
  user_avatar!: string;
  user_name!: string;
  user_last_name!: string;
  remove_photo: string = '0';
  user!: User;

  ngOnInit(): void {
    
    //load timezone data
    this.dataService.getTimezones()
      .subscribe(data => {
        this.timezones = data   
      });
    
    this.myForm.patchValue({
      'first_name' : this.user.first_name,
      'last_name' : this.user.last_name,
      'timezone' : this.user.timezone,
      'jobrole' : this.user.active_workspace_jobrole,
      'location' : this.user.location,
    });

    this.user_avatar = this.user.avatar;
    this.user_name = this.user.first_name + ' ' + this.user.last_name;

  }

  close() {
    this.ref.close();
  }

  save() {

    this.loadingService.show();
    this.loading = true;

    //submit form data using FormData instead of JSON object to preserve File object for avatar field
    const formData = new FormData();
    formData.append('first_name', this.myForm.get('first_name')?.value);
    formData.append('last_name', this.myForm.get('last_name')?.value);
    formData.append('timezone', this.myForm.get('timezone')?.value);
    formData.append('jobrole', this.myForm.get('jobrole')?.value || '');
    formData.append('location', this.myForm.get('location')?.value || '');
    formData.append('avatar', this.myForm.get('avatar')?.value);
    formData.append('remove_photo', this.remove_photo);

    this.userService.updateProfile(formData)
      .pipe(
        mergeMap(() => {

          if(this.user.settings?.onboarding_checklist?.edit_profile_done == '0') {
            //additional API call to update onboarding state
            return this.userService.updateSetting({
              onboarding_checklist: {
                edit_profile_done: '1'
              }
            });
          }

          return of(true);
        })
      )
      .subscribe(
        data => {
          this.loadingService.hide();
          this.loading = false;
          this.close();
          
          this.checklistService.updateChecklist();

          this.toastMessageService.show(
            {
              severity: 'success',
              summary: 'Done!',
              detail: 'Profile updated successfully.'
            }
          );

          this.mixpanelService.track('My Account - Update profile');

        },
        err => {
          this.loadingService.hide();
          this.loading = false;
          
          this.toastMessageService.show(
            {
              severity: 'error',
              summary: 'Error',
              detail: err?.message || err
            }
          );
        
        }
      );

  }

  getFile(event: any) {

    this.remove_photo = '0';

    let file = event.currentFiles.pop();

    if(file) {
      this.myForm.patchValue({
        avatar: file
      });
      
      this.user_avatar = file.objectURL.changingThisBreaksApplicationSecurity;   
    }
    
  }

  removePhoto() {

    this.remove_photo = '1';

    this.myForm.patchValue({
      avatar: '',
    });

    this.user_avatar = '';

  }

}
