import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToroLoadingService {

  constructor() { }

  private showLoadingSubject = new BehaviorSubject<boolean>(false);

	showLoading = this.showLoadingSubject.asObservable();

  show() {
    this.showLoadingSubject.next(true);
  }

  hide() {
    this.showLoadingSubject.next(false);
  }

}
