import { Pipe, PipeTransform } from '@angular/core';
import { ToroArrayService } from '../services/array.service';

@Pipe({
  name: 'flattenArray',
  standalone: true
})
export class ToroFlattenArrayPipe implements PipeTransform {

  constructor(
    private arrayService: ToroArrayService
  ) { }
  
  /**
   * Flatten an array of objects based on the specified property and return the flatten array.
   */
  transform(arrayInput: any[], propertyToFlatten: string): any[] {
    return this.arrayService.flattenArray(arrayInput, propertyToFlatten);
  }

}
