import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiResponse } from '../models/genericApiResponse';
import { TaskType } from '../models/tasktype';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroTaskTypeService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  private reloadTaskTypesSubject = new Subject<boolean>();

	reloadTaskTypes = this.reloadTaskTypesSubject.asObservable();

  triggerReloadTaskTypes() {
    this.reloadTaskTypesSubject.next(true);
  }
  constructor(
    private http: HttpClient
  ) { }

  createTaskType(data: any): Observable<TaskType> {
    return this.http.post<GenericApiResponse>(this.API_BASE + `tasktypes`, data)
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          //successful add
          this.triggerReloadTaskTypes();
          return res.data;

        } else {

          throw new Error(res.message);

        }

      })
    );

  }

  updateTaskType(id:number, data: any): Observable<boolean> {
    return this.http.put<GenericApiResponse>(this.API_BASE + `tasktypes/${id}`, data)
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          //successful add
          this.triggerReloadTaskTypes();
          return res.data;

        } else {

          throw new Error(res.message);

        }

      })
    );
  }

  deleteTaskType(id:number, data: any): Observable<boolean> {

    return this.http.delete<GenericApiResponse>(this.API_BASE + `tasktypes/${id}`, {
      params: data
    })
    .pipe(
      map(res => {

        if(res.status == 'OK') {
          //successful
          this.triggerReloadTaskTypes();
          return res.data;

        } else {

          throw new Error(res.message);

        }

      })
    );
  }


}
