import { InjectionToken } from '@angular/core';

export interface ToroLibConfig {
  apiUrl: string;
}

export const TORO_LIB_CONFIG = new InjectionToken<ToroLibConfig>(
  'torolib.config',
  {
    factory: () => DefaultConfig
  }
);

export const DefaultConfig: ToroLibConfig = {
  apiUrl: ''
};