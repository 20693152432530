export const environment = {
  id: 'staging',
  production: false,
  appBaseUrl: 'https://app.torostampede.com',
  apiUrl: 'https://api.torostampede.com/api/',
  socketIoServerUrl: 'https://api.torostampede.com',
  rollbarAccessToken: 'cc285adb3f504a18a5a38e82592802e0',
  stripePublishableApiKey: 'pk_test_51GVrdYAxjCXla5Rf7onjRbGZCZQsRF3VmTNfcAK7cQpLPldMephFmrsv1KutCCjNimrol77Z3pz5zcCPzr0qs93X00LhsWrlT4',
  stripeProductIdToroMonthly: 'price_1Jar9UAxjCXla5RfKKnDI3UU',
  stripeProductIdToroAnnually: 'price_1Jar9UAxjCXla5RfW7wsEALk',
  enableMixpanelTracking: true,
  enableSignUpPage: false,
  oneSignalAppId: 'dd26d1ba-81e3-4c12-8b6d-2475f062932f',
  oneSignalSafariIdWeb: 'web.onesignal.auto.25811132-3882-4d1b-a1e7-3632ed052841'
};
