import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemExcluder',
  standalone: true
})
export class ToroItemExcluderPipe implements PipeTransform {

  /**
   * Finds an item in the array by a specific key and drop it out of the array.
   */
  transform(arrayInput: any[], key: string, value: any): any[] {

    let index = arrayInput.findIndex(elem => {
      return elem[key] == value;
    });

    if(index > -1) {
      arrayInput.splice(index, 1);
    }

    return arrayInput;
    
  }

}
