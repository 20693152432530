import { Component } from '@angular/core';

@Component({
  selector: 'toro-lib-dummy',
  standalone: true,
  template: `
    <p>
      toro-lib-dummy works!
    </p>
  `,
  styles: [
  ]
})
export class ToroDummyComponent {

}
