import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../models/country';
import { Timezone } from '../models/timezone';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToroDataService {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Get list of countries.
   */
  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>('assets/json/countries.json')
      .pipe(
        map(countries => {

          //move certain countries to the top of the list
          let found = countries.filter(row => {
            return row.name == 'Malaysia';
          });
          
          found.forEach(row => {
            let deleted = countries.splice( countries.indexOf(row), 1).pop();
            countries.splice( 0, 0, <Country>deleted);
          });
          
          return countries;

        })
      ); 
  }

  /**
   * Get list of timezones.
   */
   getTimezones(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>('assets/json/timezones.json'); 
  }

}
