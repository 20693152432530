import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericApiResponse } from '../models/genericApiResponse';
import { User } from '../models/user';
import { WorkspaceUser } from '../models/workspace';
import { PlanCapabilitiesChecker, Workspace, WorkspaceUserLevel } from '../models/workspace';
import { ToroUserService } from './user.service';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroWorkspaceUserService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
    private userService: ToroUserService
  ) { }

  //The user's capabilities based on roles in the context of the workspace (the active workspace or the provided workspace id)
  workspaceUserLevels: WorkspaceUserLevel[] = [
    {
      userlevel_id: 3,
      label: 'Administrator',
      capabilities: [
        'workspace-view',
        'workspace-edit-settings',
        'workspace-edit-people',
        'workspace-edit-permissions',
        'workspace-view-access-logs',
        'workspace-create',
        'workspace-quick-switch',
        'company-view',
        'company-create',
        'company-edit',
        'company-archive',
        'company-unarchive',
        'company-delete',
        'projects-view',
        'project-view',
        'project-create',
        'project-edit',
        'project-archive',
        'project-unarchive',
        'project-delete',
        'project-templates-manage',
        'history-people',
        'history-export',
        'history-share',
        'workspace-plan-view',
        'workspace-billing-view',
      ]
    },
    {
      userlevel_id: 2,
      label: 'Manager',
      capabilities: [
        'workspace-quick-switch',
        'company-view',
        'company-create',
        'company-edit',
        'company-archive',
        'company-unarchive',
        'projects-view',
        'project-view',
        'project-create',
        'project-edit',
        'project-archive',
        'project-unarchive',
        'project-delete',
        'project-templates-manage',
        'history-people',
        'history-export',
        'history-share',
      ]
    },
    {
      userlevel_id: 1,
      label: 'Member',
      capabilities: [
        'workspace-quick-switch',
        'projects-view',
        'history-people',
      ]
    }
  ];

  /**
   * Update the workspace user profile information.
   * @param data User profile data
   */
  updateProfile(workspace_id: number, user_id: number, userdata: any): Observable<boolean> {

		return this.http.put<GenericApiResponse>(this.API_BASE + `workspaces/${workspace_id}/users/${user_id}`, userdata)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

  /**
   * Check user permisison to access an entity based on the user role in a particular workspace and the capabilities requested. This method only controls access to an entity on the surface level and does not check against the entity details like status or ownership etc.
   */
  checkAccess(planCapabilities: PlanCapabilitiesChecker[], workspace_id: number | undefined = undefined): Observable<boolean> {
    
    return this.userService.reloadUser
      .pipe(
        map(data => {

          let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

          let workspace!: Workspace;
          let userlevel_id: number | undefined;
          let workspacePlan: string = 'free';

          //determine which workspace we will be referencing from
          if(workspace_id) {
            //reference from the provided workspace id
            workspace = <Workspace>user.workspaces.find(ws => ws.id == workspace_id);
            userlevel_id = workspace?.pivot?.userlevel_id;
            workspacePlan = workspace?.plan;
          } else {
            //reference from the active workspace
            workspace = <Workspace>user.workspaces.find(ws => ws.id == user.active_workspace_id);
            userlevel_id = workspace?.pivot?.userlevel_id;
            workspacePlan = workspace?.plan;
          }
          
          //check if the workspace plan should be allowed to see this functionality
          let planCapability = planCapabilities.find(planCap => {
            return planCap.plan == workspacePlan;
          });

          if(planCapability && userlevel_id) {
            //at this point, it means the workspace plan allows this functionality
            //now we check if the user level has the capabilities to see this functionality
      
            let listOfCapabilitiesInPlan = planCapability.capabilities;

            //get a single role definition
            let oneRole = this.workspaceUserLevels.find(role => {
              return role.userlevel_id == userlevel_id;
            });

            if(oneRole) {
              //do the capabilities checking
              return oneRole.capabilities.some(caps => {
                return listOfCapabilitiesInPlan.includes(caps);
              });
            }
      
          }    

          return false;

        })
      );

  }

  /**
   * Check if the workspace belongs to a certain plan. Returns true is yes, false if no.
   */
  // isWorkspacePlan(plans: string[], workspace_id: number | undefined): Observable<boolean> {
    
  //   return this.userService.reloadUser
  //     .pipe(
  //       map(data => {
          
  //         let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

  //         let plan: string | undefined;

  //         //use user active workspace id if workspace_id not provided
  //         if(workspace_id === undefined) {
  //           workspace_id = user?.active_workspace_id;
  //         }

  //         plan = user.workspaces.find(ws => ws.id == workspace_id)?.plan;

  //         console.log(plan);
          

  //         return true;
  //       })
  // }
  
  /**
   * Send invites for joining a specific workspace.
   */
  sendInvites(data: {}): Observable<boolean> {

		return this.http.post<GenericApiResponse>(this.API_BASE + `invites`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

  /**
   * Send request to accept a workspace invitation using an invitation token.
   */
  acceptInvite(token: string, timezone_offset_minutes: number): 
  Observable<{
    message?: string; 
    data?: string;
  }> {

    let data = {
      'token': token,
      'timezone_offset': timezone_offset_minutes
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `invites/accept`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return {
              'data': res.data,
              'message': res.message
            };

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

  /**
   * Get list of workspace users.
   */
  getUsers(id: number): Observable<WorkspaceUser[]> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `workspaces/${id}/users`)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            return res.data;
          } else {
            throw new Error(res.message);
          }
            
        })
      );

  }

  /**
   * Activate a workspace user.
   */
  activateUser(workspace_id: number, user_id: number): Observable<boolean> {

    return this.http.put<GenericApiResponse>(this.API_BASE + `workspaces/${workspace_id}/users/${user_id}/activate`, {})
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

  /**
   * Deactivate a workspace user.
   */
   deactivateUser(workspace_id: number, user_id: number): Observable<boolean> {

    return this.http.put<GenericApiResponse>(this.API_BASE + `workspaces/${workspace_id}/users/${user_id}/deactivate`, {})
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

}
