import { ModuleWithProviders, NgModule } from '@angular/core';
import { TORO_LIB_CONFIG, ToroLibConfig } from './toro-lib.config';


@NgModule({
  declarations: [
  ],
  imports: [
  ],
  exports: [
  ]
})
export class ToroLibModule { 

  /**
   * Initialise any config for use within the library
   */
  static withConfig(setting?: ToroLibConfig): ModuleWithProviders<ToroLibModule> {
    return {
      ngModule : ToroLibModule,
      providers: [
        {
          provide : TORO_LIB_CONFIG,
          useValue: setting
        },
      ],
    };
  }

}
