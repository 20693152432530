import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToroArrayService {

  constructor() { }

  /**
   * Take a straight array and return a new array with the elements grouped by a given object property.
   * @param mainArray The input array.
   * @param property The object property you want to group the array elements with.
   * @param primaryKeyProp A property to validate against in case you don't want to push null objects into the array.
   * @returns 
   */
  groupBy(mainArray: any[], property: string, primaryKeyProp?: string): any[] {

    return mainArray.reduce(function (accumulator: any, object: any) {
      // get the value of our object (client_id in our case) to use for group the array as the array key
      const key = object[property];
      // if the current value is similar to the key (client_id) don't accumulate the transformed array and leave it empty
      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      if(primaryKeyProp) {
        if(object[primaryKeyProp]) {
          // add the value to the array if id is not null, meaning it is a valid project record
          accumulator[key].push(object);
        }
      } else {
        //no primary key check, just add value to the array
        accumulator[key].push(object);
      }

      // return the transformed array
      return accumulator;

      //set the initial value of reduce() to an empty array
    }, []);

  }

  groupByArrayOfObjects(mainArray: any[], groupByProperty: string, newChildrenProperty: string, otherParentProperties: string[]) {

    let processedKeyValue: any[] = [];

    return mainArray.reduce(function (accumulator: any, object: any) {

      // get the value of our object (client_id in our case) to use for group the array as the array key
      const keyValue = object[groupByProperty];

      let checker = processedKeyValue.some(val => {
        return val == keyValue;
      });

      if(!checker) {
          
        processedKeyValue.push(keyValue);

        let children = mainArray.filter(item => {
          return item[groupByProperty] == keyValue;
        });

        if(children) {

          let parentProperties: any = {};

          otherParentProperties.forEach(prop => {
            parentProperties[prop] = object[prop];
          });

          accumulator.push(
            {
              [groupByProperty]: keyValue,
              ...parentProperties,
              [newChildrenProperty]: children
            }
          )
        }
      
      }

      // return the transformed array
      return accumulator;

    }, []);

  }

  /** Filter an array by a specific key-value. */
  filterArray(arrayInput: any[], key: string, value: any): any[] {

    if(key.includes('.')) {
      //provided key has a dot to access nested property. Only 2-level deep is allowed or in other words, only one dot.

      let keys = key.split('.');

      //destructure the keys into individual var
      let [key1, key2] = keys;

      return arrayInput.filter(elem => {
        return elem[key1][key2] == value;
      });
      
    } else {

      return arrayInput.filter(elem => {
        return elem[key] == value;
      });
  
    }
  }

  /**
   * Flatten an array of objects based on the specified property and return the flatten array.
   * @param arrayInput Array of objects
   * @param propertyToFlatten The property name you want to extract and flatten from the array of objects
   * @returns The flatten array.
   */
   flattenArray(arrayInput: any[], propertyToFlatten: string): any[] {

    /*
    arrayInput = [{ id: 1, name: 'John'}, { id: 2, name: 'Jane'}, { id: 3, name: 'Mike'}]
    
    {{ arrayInput| mapToFlatArrayPipe: 'name' }}

    Returns: ['John', 'Jane', 'Mike']
    */

    return arrayInput.map(item => {
      return item[propertyToFlatten];
    });

  }

}
