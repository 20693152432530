import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class ToroDateFormatPipe implements PipeTransform {

  transform(date: string, formatStr: string): string {
    
    const formatted = format( parseISO(date), formatStr );

    if(formatStr == 'i') {
      //special handling for 'i' formatting

      const dayNames = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'S'];

      //Monday=1...Sunday=7
      const dayIndex = parseInt(formatted);

      //minus one to match array zero-based index
      return dayNames[dayIndex-1];

    }
      
    return formatted;

  }

}
