import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { ToroDateService } from '../services/date.service';

@Pipe({
  name: 'dateDistanceFromNow',
  standalone: true
})
export class ToroDateDistanceFromNowPipe implements PipeTransform {

  constructor(
    private dateService: ToroDateService
  ) { }

  transform(dateStringUtc: string): string {

    let distance;

    if(dateStringUtc) {
      
      const dateStringUtcIso = this.dateService.formatUtcTimeToIso(dateStringUtc);

      distance = formatDistanceToNow( parseISO(dateStringUtcIso) );

      switch (distance) {
        case '1 day':
        case 'about 24 hours':
          distance = 'yesterday';
          break;
        default:
          distance =  distance + ' ago';
      }

    } else {
      distance = 'never';
    }

    return distance;

  }

}
