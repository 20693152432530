import { Injectable, inject } from '@angular/core';
import { GenericApiResponse } from '../models/genericApiResponse';
import { User } from '../models/user';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MarkedOffDay } from '../models/timeTracking';
import { Subject } from 'rxjs';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroUntrackedService {
  
  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  private toggleEditDaysDialogSubject = new Subject<boolean>();

  toggleEditDaysDialog = this.toggleEditDaysDialogSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  registerOffDay(date: MarkedOffDay[]) {

    let user: User = JSON.parse( localStorage.getItem('user') || '{}' );

    const data = {
      'workspace_id': user.active_workspace_id,
      'dates': date
    }

    return this.http.post<GenericApiResponse>(this.API_BASE + `offdays`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {

            return res.data;

          } else {

            throw new Error(res.message);

          }

        })
      );

  }

  toggleEditDays(toggle: boolean) {
    this.toggleEditDaysDialogSubject.next(toggle);
  }
}
