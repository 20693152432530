import { Inject, Injectable, inject } from '@angular/core';
import { TORO_LIB_CONFIG, ToroLibConfig } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroDummyService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    @Inject(TORO_LIB_CONFIG) public toroLibConfig: ToroLibConfig
  ) { }

  testing() {
    console.log('This is config from ToroLib ToroDummyService', this.toroLibConfig);
    console.log('This is API_BASE from ToroLib ToroDummyService', this.API_BASE);
  }
}
