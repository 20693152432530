import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, ReplaySubject } from 'rxjs';

import { Workspace } from '../models/workspace';
import { GenericApiResponse } from '../models/genericApiResponse';
import { delay, map, mergeMap } from 'rxjs/operators';
import { ToroFilterUsersPipe } from '../pipes/filter-users.pipe';
import { TORO_LIB_CONFIG } from '../toro-lib.config';

@Injectable({
  providedIn: 'root'
})
export class ToroWorkspaceService {

  private readonly API_BASE = inject(TORO_LIB_CONFIG).apiUrl;

  constructor(
    private http: HttpClient,
    private filterUsers: ToroFilterUsersPipe
  ) { }

  private reloadActiveTabSubject = new Subject<number>();

	reloadActiveTab = this.reloadActiveTabSubject.asObservable();

  triggerReloadActiveTab(key: number) {
    this.reloadActiveTabSubject.next(key);
  }

  private reloadWorkspaceSubject = new Subject<boolean>();

	reloadWorkspace = this.reloadWorkspaceSubject.asObservable();

  triggerReloadWorkspace() {
    this.reloadWorkspaceSubject.next(true);
  }

  //temp storage when navigating from listing to detail workspace page
  private openWorkspaceSubject = new ReplaySubject<Workspace>(1);
  
  getOpenWorkspace = this.openWorkspaceSubject.asObservable();

  /**
   * Get listing of workspaces the user has access to.
   */
  getAll(): Observable<Workspace[]> {

    return this.http.get<GenericApiResponse>(this.API_BASE + `workspaces`)
      .pipe(
        map(res => {
          // console.log('workspaces', res);
          
          if(res.status == 'OK') {
            return res.data;
          } else {
						throw new Error(res.message);
          }
            
        }),
        map((workspaces: Workspace[]) => {

          workspaces.forEach(ws => {

            //count active users in workspace
            ws.active_users_count = this.filterUsers.transform(ws.users, true, 'status', 1).length;

          });

          return workspaces;

        })
      );

  }

  /**
   * Get a single workspace the user has access to.
   */
  getOne(id: number, forceApiReload: boolean = false): Observable<Workspace> {

    if(forceApiReload) {

    }

    return this.http.get<GenericApiResponse>(this.API_BASE + `workspaces/${id}`)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            this.openWorkspaceSubject.next(res.data);
            return res.data;
          } else {
            throw new Error(res.message);
          }
            
        }),
        map((workspace: Workspace) => {
          return workspace;

        })
      ); 

  }

  /**
   * Update details for a specific workspace.
   */
  updateWorkspace(id: number, data: any): Observable<boolean> {

		return this.http.put<GenericApiResponse>(this.API_BASE + `workspaces/${id}`, data)
      .pipe(
        mergeMap(res => {

          if(res.status == 'OK') {
            //successful change

            return this.getOne(id, true);

          } else {

            throw new Error(res.message);
            
          }
            
        }),
        delay(100),
        map(() => {
          this.triggerReloadWorkspace();
          return true;
        })
      );

  }

  /**
   * Createa a new personal workspace.
   */
  createWorkspace(data: any): Observable<Workspace> {

		return this.http.post<GenericApiResponse>(this.API_BASE + `workspaces`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful add

            return res.data;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

}
