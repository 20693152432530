import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, parseISO } from 'date-fns';
@Pipe({
  name: 'checkDormantProject',
  standalone: true
})
export class ToroCheckDormantProjectPipe implements PipeTransform {

  transform(dateStringUtc: string): boolean {

    let is_dormant: boolean = false;
    
    let distance = differenceInDays( Date.now(), parseISO(dateStringUtc) );

    // console.log('distance ', distance);

    //if more than 90 days, consider dormant
    if(distance > 90) is_dormant = true;
    
    return is_dormant;

  }
}
