import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ToroUserService, ToroProjectService, ToroTimerService } from 'toro-lib';

// install Swiper modules
SwiperCore.use([
  Pagination,
]);

@Component({
  selector: 'app-dialog-onboarding',
  templateUrl: './dialog-onboarding.component.html',
  styleUrls: ['./dialog-onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogOnboardingComponent implements OnInit {

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private userService: ToroUserService,
    private router: Router,
    private projectService: ToroProjectService,
    private timerService: ToroTimerService,
    private mixpanelService: MixpanelService,
  ) { }

  name: 'Timer' | 'Project' | 'Google Calendar' | 'Dock' = 'Timer';

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
    allowTouchMove: true,
    autoHeight: false,
  };

  currentStep = 0

  checkboxSubject = new Subject<boolean>();
  checkboxSubjectSubscription!: Subscription;

  ngOnInit(): void {

    this.name = this.config.data.onboardingName;

    this.mixpanelService.track(`${this.name} Onboarding - Opened`);

    //save hide_onboarding or hide_project_onboarding user data
    this.checkboxSubject
      .pipe(
        debounceTime(250),
        switchMap(val => {

          let key: string = "";

          if(this.name == 'Timer') {
            key = 'hide_onboarding';
          } else if(this.name == 'Project') {
            key = 'hide_project_onboarding';
          }

          return this.userService.updateSetting({
            [key]: (val) ? '1' : '0'
          });
        })
      )
      .subscribe(
        data => {
        },
        err => {
        }
      );

  }

  close(fromHeader: boolean = false, state: boolean = true, createCompany: boolean = false) {
    this.ref.close(state);
    if(!fromHeader) {

      this.mixpanelService.track(`${this.name} Onboarding - Completed`);

      switch(this.name) {
        case 'Timer':
          this.timerService.finishedOnboarding();
        break; case 'Project':
          this.checkboxSubject.next(true);
          if(createCompany) {
            this.projectService.finishedProjectOnboarding('company');
          } else {
            this.projectService.finishedProjectOnboarding('project');
          }
        break; case 'Google Calendar':
          this.router.navigateByUrl('/timer')
        break;
      }

    } else {

      this.mixpanelService.track(`${this.name} Onboarding - Closed`);

      if(this.name == 'Project') {
        this.checkboxSubject.next(true);
      }

    }
  }

  dontShowAnymore(status: boolean) {
    this.checkboxSubject.next(status);
  }

  nextStep() {
    this.currentStep = this.currentStep + 1;
    this.swiper?.swiperRef.slideNext();
  }

  onSlideChange(event: any) {
    this.currentStep = event[0].activeIndex;
  }

  prevStep() {
    this.currentStep = this.currentStep - 1;
    this.swiper?.swiperRef.slidePrev();
  }

  // goToStep(index = 0) {
  //   this.currentStep = index;
  // }

  // close() {
  //   this.ref.close();
  // }

  ngOnDestroy() {
    this.checkboxSubjectSubscription?.unsubscribe();
  }

}
